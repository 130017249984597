import { Theme } from '@mui/material/styles';

import { gold, hala } from "./MUITheme";


const themes: { [key: string]: Theme; } = {
  'gold': gold,
  'hala': hala
};

export function getTheme(name: string) {
  return themes[name];
}
