/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createTheme } from "@mui/material/styles";
import { Poppins } from "next/font/google";
const poppins = Poppins({
  weight: "500",
  subsets: ["devanagari"],
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#109279",
    },
    secondary: {
      main: "#267AF7",
    },
  },
  components: {

    MuiLink: {
      styleOverrides: {
        root: () => ({
          // color: "#78560C",
          textDecoration: "none !important",

        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: () => ({
          borderRadius: "20px",
          border: "1px solid #E5E5E5",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        // @ts-ignore
        root: ({ theme, ownerState }) => ({

          borderRadius: "30px",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "22px",
          // background: 'linear-gradient(90deg, #0796C4 0%, #01C673 100%)',
          padding: "10px 20px",
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            // this is the same as writing:
            // backgroundColor: 'var(--mui-palette-background-paper)',
            background: 'linear-gradient(90deg, #0796C4 0%, #01C673 100%)',
          }),
          // '&:hover': {
          //   background: "var(--gold, linear-gradient(90deg, #9A610C 3.39%, #9B5606 36.46%, #7B4F0D 72.92%, #AE6611 100%))",
          // },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: () => ({
          '& .MuiInputLabel-root': {
            color: "#737A99", // Placeholder color
            fontSize: '1rem', // Adjust font size as needed
            '&.Mui-focused': {
              color: "#737A99", // Change color when input is focused
            },
          },
        }),
      },
    },
    // MuiDivider: {
    //   styleOverrides: {
    //     root: () => ({

    //       background: "var(--gold, linear-gradient(90deg, #9A610C 3.39%, #9B5606 36.46%, #7B4F0D 72.92%, #AE6611 100%))",
    //       border: "none",
    //       height: "1px",
    //     }),
    //   },
    // },
  },
  typography: {
    fontFamily: poppins.style.fontFamily,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.167,
      // color: "var(--900, #7A4B04);",
      letterSpacing: "-0.01562em",

    },
    body2: {
      color: "#737A99",
      fontSize: "16px",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 400,
    },
    h6: {
      color: "var(--primary-black-900, #0C0507)",
      fontSize: "24px",
      fontWeight: 600,
    },
    menu: {

      // color: "#78560C",
      textDecoration: "none !important",
      // // @ts-ignore
      // '&:hover': {
      //   color: "#D9B85A !important",
      // },
      // @ts-ignore
      '&.active': {
        // background: "linear-gradient(90deg, #D9B85A 3.39%, #B68012 36.46%, #D4AB42 72.92%, #C7992E 100%)",
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 'bold',
      },

    }
  },
  colors: {
    navbar: "rgba(242, 242, 242, 0.70)",
    body: "#FFFFFF",
    backgroundTopBody: "linear-gradient(180deg, rgba(251, 252, 255, 0.00) 0%, #FBFCFF 100%)",
    // menu: "#78560C",
    // menuActive: "linear-gradient(90deg, #D9B85A 3.39%, #B68012 36.46%, #D4AB42 72.92%, #C7992E 100%)"
  },


});
export default theme;