

import { AppConfig } from '@hapchain-nft/hooks/dist/esm/types/config';
import appConfigJson from '../../config/app.json';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from "@apollo/client";
export function getAppConfig(): AppConfig {
  return appConfigJson as AppConfig;
}
export function apollClient(chainId: number, authToken: string) {
  const appConfig = getAppConfig();
  const chain = appConfig.chains?.find((chain) => chain.chainId == chainId);

  if (!chain) return null;
  const httpLink = createHttpLink({
    uri: chain?.gqlEndpoint || "",
  });
  const authLink = new ApolloLink((operation, forward) => {

    if (!authToken) return forward(operation);
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    }));
    return forward(operation);
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });
  return client;
}
