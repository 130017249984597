import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {

  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
  },
  colors: {
    navbar: "#ffffff",
  },
});
export default theme;