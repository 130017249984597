/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import createEmotionCache from "@/createEmotionCache";
import { getAppConfig } from "@/services/app";
import "@/styles/globals.css";
import { EmotionCache } from "@emotion/cache";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import type { AppProps, AppType } from "next/app";
import { useEffect } from "react";
import { HapchainTestnet, Hapchain } from "@thirdweb-dev/chains";
import { SnackbarProvider } from "notistack";
import { AppConfigProvider } from "@hapchain-nft/hooks";
import { CacheProvider } from "@emotion/react";
import Head from "next/head";
import { getTheme } from "@/theme";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const appConfig = getAppConfig();
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
// export default function MyApp(props: MyAppProps) {
const tempTheme = getTheme("gold");
const theme = responsiveFontSizes(tempTheme);
const MyApp: AppType = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = (Component as any).getLayout || ((page: any) => page);
  useEffect(() => {
    const body = document.body;

    body.setAttribute("data-theme", "defaultTheme");
  }, []);

  return (
    <ThirdwebProvider
      clientId="9291a6a971d46931ee596fe63f12ecfc"
      supportedChains={[
        Hapchain,
        {
          ...HapchainTestnet,
          rpc: ["https://jsonrpc-test.hap.land"],
        },
      ]}
      activeChain={{
        ...HapchainTestnet,
        rpc: ["https://jsonrpc-test.hap.land"],
      }}
    >
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AppConfigProvider config={appConfig}>
              <CssBaseline />
              <SnackbarProvider maxSnack={3}>
                {getLayout(<Component {...pageProps} />)}
              </SnackbarProvider>
            </AppConfigProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </ThirdwebProvider>
  );
};
export default MyApp;
